<template>
  <div style="height: inherit" v-if="permission.access">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Search task"
              v-model="search.search_data"
            />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <!-- <div class="dropdown">
          <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item> Reset Sort </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: $route.name,
                query: { ...$route.query, sort: 'title-asc' },
              }"
            >
              Sort A-Z
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: $route.name,
                query: { ...$route.query, jaker: 'title-asc' },
              }"
            >
              Sort A-Z
            </b-dropdown-item>
          </b-dropdown>
        </div> -->
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="150"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <div class="todo-task-list media-list">
          <li
            class="todo-item"
            v-for="(task, index) in tasks.data"
            :key="index"
            v-if="tasks.data.length"
            @click="getTaskModel(task.id)"
          >
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="font-weight-bolder">
                  {{ task.title }}
                </div>
                <div class="font-small-2 text-muted">
                  <span v-if="task.main_task" class="text-primary">
                    <b>{{ task.main_task.title }}</b
                    >,
                  </span>
                  <span v-if="task.project">({{ task.project.title }})</span>
                </div>
              </div>
              <div class="col-lg-3">
                <Progress
                  :percent="task.done_percentage > 0 ? task.done_percentage : 0"
                  :stroke-width="10"
                />
                <small class="text-muted mr-1">
                  <span>{{ task.start_date | date_time_format_short }}</span> -
                  <span class="text-danger">
                    {{ task.end_date | date_time_format_short }}
                  </span>
                </small>
              </div>
              <div class="col-lg-3 text-right">
                <b-badge
                  v-if="task.duration > 0"
                  pill
                  style="margin: 2px"
                  :variant="'primary'"
                >
                  {{ task.duration }} Hour
                </b-badge>
                <b-badge
                  v-if="task.qty > 0"
                  pill
                  style="margin: 2px"
                  :variant="'success'"
                >
                  {{ task.qty }} Qty.
                </b-badge>
                <b-badge
                  style="margin: 2px"
                  pill
                  :variant="
                    task.priority == 1
                      ? 'light-danger'
                      : task.priority == 2
                      ? 'light-primary'
                      : 'light-warning'
                  "
                >
                  {{
                    task.priority == 1
                      ? "High"
                      : task.priority == 2
                      ? "Medium"
                      : "Low"
                  }}
                </b-badge>
                <b-badge
                  style="margin: 2px"
                  pill
                  :variant="
                    task.is_progress == 1
                      ? 'light-warning'
                      : task.is_progress == 2
                      ? 'light-primary'
                      : task.is_progress == 3
                      ? 'light-success'
                      : 'light-danger'
                  "
                >
                  {{
                    task.is_progress == 1
                      ? "Not Started"
                      : task.is_progress == 2
                      ? "On Going"
                      : task.is_progress == 3
                      ? "Finished"
                      : "Cancel"
                  }}
                </b-badge>
              </div>
              <div class="col-lg-2">
                <div class="todo-item-action justify-content-end">
                  <b-avatar
                    v-for="(employee, employee_index) in task.employees"
                    :key="employee_index"
                    v-if="task.employees.length"
                    style="margin-left: 5px"
                    variant="secondary"
                    v-b-tooltip.hover
                    :title="employee.name"
                    :text="getChar(employee.name)"
                  />
                </div>
              </div>
            </div>
          </li>
        </div>
        <div class="no-results" :class="{ show: !tasks.data.length }">
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <portal to="search_data"> </portal>

    <portal to="pagination">
      <div class="m-1">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ tasks.from ? tasks.from : 0 }} to
              {{ tasks.to ? tasks.to : 0 }} of
              {{ tasks.total ? tasks.total : 0 }}
              entries</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <pagination
              :data="tasks"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </div>
    </portal>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <div
        class="sidebar-left"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      >
        <div class="sidebar">
          <div class="sidebar-content todo-sidebar">
            <div class="todo-app-menu">
              <div class="add-task" v-if="permission.create">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="getAddTaskModel"
                >
                  Add Task
                </b-button>
              </div>
              <vue-perfect-scrollbar
                :settings="60"
                class="sidebar-menu-list scroll-area"
              >
                <!-- Filters -->
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.is_progress == null"
                    button
                    @click="getIsProgress(null)"
                  >
                    <feather-icon icon="ListIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1">Tasks</span>
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.is_progress == 1"
                    button
                    @click="getIsProgress(1)"
                  >
                    <feather-icon icon="LayersIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1"
                      >Not Started</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.is_progress == 2"
                    button
                    @click="getIsProgress(2)"
                  >
                    <feather-icon
                      icon="RotateCcwIcon"
                      size="18"
                      class="mr-75"
                    />
                    <span class="align-text-bottom line-height-1"
                      >On Going</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.is_progress == 3"
                    button
                    @click="getIsProgress(3)"
                  >
                    <feather-icon icon="CheckIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1"
                      >Completed</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.is_progress == 4"
                    button
                    @click="getIsProgress(4)"
                  >
                    <feather-icon icon="TrashIcon" size="18" class="mr-75" />
                    <span class="align-text-bottom line-height-1">Deleted</span>
                  </b-list-group-item>
                </b-list-group>

                <!-- Tags -->
                <div class="mt-3 px-2 d-flex justify-content-between">
                  <h6 class="section-label mb-1">Work Status</h6>
                </div>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == null"
                    button
                    @click="getWorkType(null)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-secondary`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >All Task</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 4"
                    button
                    @click="getWorkType(4)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-success`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Assign Task</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 1"
                    button
                    @click="getWorkType(1)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-info`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Daily Task</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 2"
                    button
                    @click="getWorkType(2)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-warning`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Weekly Task</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 4"
                    button
                    @click="getWorkType(4)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-danger`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Quarterly Work</span
                    >
                  </b-list-group-item>
                </b-list-group>
                <b-list-group class="list-group-filters">
                  <b-list-group-item
                    :active="search.work_type == 3"
                    button
                    @click="getWorkType(3)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-danger`"
                    />
                    <span class="align-text-bottom line-height-1"
                      >Monthly Task</span
                    >
                  </b-list-group-item>
                </b-list-group>

                <!-- Tags -->
                <div class="mt-3 px-2 d-flex justify-content-between">
                  <h6 class="section-label mb-1">Priority</h6>
                </div>

                <b-list-group class="list-group-labels">
                  <b-list-group-item
                    :active="search.priority == null"
                    button
                    @click="getPriority(null)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-info`"
                    />
                    <span>All</span>
                  </b-list-group-item>
                  <b-list-group-item
                    :active="search.priority == 1"
                    button
                    @click="getPriority(1)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-danger`"
                    />
                    <span>High</span>
                  </b-list-group-item>
                  <b-list-group-item
                    :active="search.priority == 2"
                    button
                    @click="getPriority(2)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-primary`"
                    />
                    <span>Medium</span>
                  </b-list-group-item>
                  <b-list-group-item
                    :active="search.priority == 3"
                    button
                    @click="getPriority(3)"
                  >
                    <span
                      class="bullet bullet-sm mr-1"
                      :class="`bullet-warning`"
                    />
                    <span>Low</span>
                  </b-list-group-item>
                </b-list-group>
              </vue-perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </portal>

    <Drawer
      title="Task Manage"
      v-model="task_model"
      width="425"
      :mask-closable="false"
      v-if="permission.manage"
    >
      <div class="card-app-design">
        <b-badge variant="light-primary">
          {{ task.created_at | date_time_format_short }}
        </b-badge>
        <b-card-title class="mt-1 mb-75">
          {{ task.title }}
          <div class="font-small-2 text-muted">
            <span v-if="task.main_task" class="text-primary">
              <b>{{ task.main_task.title }}</b
              >,
            </span>
            <span v-if="task.project">({{ task.project.title }})</span>
          </div>
        </b-card-title>
        <b-card-text class="font-small-2 mb-2">
          {{ task.description }}
        </b-card-text>

        <div class="design-group">
          <h6 class="section-label">Members</h6>
          <!-- :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')" -->
          <b-avatar
            v-for="(employee, employee_index) in task.employees"
            :key="employee_index"
            v-if="task.employees.length"
            class="mr-1"
            variant="secondary"
            v-b-tooltip.hover
            :title="employee.name"
            :text="getChar(employee.name)"
          />
        </div>

        <div class="design-group" v-if="task.duration > 0 || task.qty > 0">
          <h6 class="section-label">Hour & Quantity</h6>
          <b-badge
            v-if="task.duration > 0"
            pill
            style="margin: 2px"
            :variant="'primary'"
          >
            {{ task.duration }} Hour
          </b-badge>
          <b-badge
            v-if="task.qty > 0"
            pill
            style="margin: 2px"
            :variant="'success'"
          >
            {{ task.qty }} Qty.
          </b-badge>
        </div>

        <div class="design-group">
          <h6 class="section-label">Status</h6>
          <b-badge
            style="margin-right: 5px"
            :variant="
              task.priority == 1
                ? 'danger'
                : task.priority == 2
                ? 'primary'
                : 'warning'
            "
          >
            {{
              task.priority == 1
                ? "High Priority"
                : task.priority == 2
                ? "Medium Priority"
                : "Low Priority"
            }}
          </b-badge>
          <b-badge
            style="margin-right: 5px"
            :variant="
              task.is_progress == 1
                ? 'warning'
                : task.is_progress == 2
                ? 'primary'
                : task.is_progress == 3
                ? 'success'
                : 'danger'
            "
          >
            {{
              task.is_progress == 1
                ? "Not Started"
                : task.is_progress == 2
                ? "On Going"
                : task.is_progress == 3
                ? "Finished"
                : "Cancel"
            }}
          </b-badge>
          <b-badge
            style="margin-right: 5px"
            :variant="
              task.work_type == 1
                ? 'warning'
                : task.work_type == 2
                ? 'primary'
                : task.work_type == 3
                ? 'success'
                : 'info'
            "
          >
            {{
              task.work_type == 1
                ? "Daily Work"
                : task.work_type == 2
                ? "Weekly Work"
                : task.work_type == 3
                ? "Monthly Work"
                : task.work_type == 4
                ? "Quarterly Work"
                : "Assign Task"
            }}
          </b-badge>
          <div style="margin-top: 3px">
            <Progress
              :percent="task.done_percentage > 0 ? task.done_percentage : 0"
              :stroke-width="8"
            />
          </div>
        </div>

        <div class="design-planning-wrapper">
          <div class="design-planning">
            <p class="card-text mb-25">Start Date</p>
            <h6 class="mb-0">
              {{ task.start_date | date_time_format_short }}
            </h6>
          </div>
          <div class="design-planning">
            <p class="card-text mb-25">Due Date</p>
            <h6 class="mb-0">
              {{ task.end_date | date_time_format_short }}
            </h6>
          </div>
        </div>
        <div v-if="task.done_percentage < 100" class="mb-2">
          <h5 class="mb-2"><b>Task Update</b></h5>
          <div class="row">
            <div class="col-md-6">
              <b-form-group label="Start Time" label-for="start_date">
                <flat-pickr
                  v-model="done_task.start_date"
                  class="form-control"
                  placeholder="Select Start Time"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                  }"
                />
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="End Time" label-for="end_date">
                <flat-pickr
                  v-model="done_task.end_date"
                  class="form-control"
                  placeholder="Select End Time"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                  }"
                />
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group label="Select Employee" label-for="employee_id">
                <Select
                  multiple
                  v-model="done_task.employee_id"
                  filterable
                  name="employee_id"
                  id="employee_id"
                  placeholder="Select Employee"
                >
                  <Option
                    :value="employee.pivot.employee_id"
                    v-for="(employee, index) in task.employees"
                    :key="index"
                    v-if="task.employees.length"
                    >{{ employee.name }}</Option
                  >
                </Select>
              </b-form-group>
            </div>
          </div>
          <div v-if="task.type != 6" class="mb-1">
            <p class="mb-0">Work Progress ({{ done_task.percentage }}%)</p>
            <div class="w-100 pr-1" style="padding-left: 5px">
              <vue-slider
                v-model="done_task.percentage"
                :silent="true"
                :min="task.done_percentage"
                :max="100"
              />
            </div>
          </div>
          <div v-if="task.type == 6" class="mb-1">
            <p class="mb-0">Done Quantity</p>
            <div class="w-100 pr-1" style="padding-left: 5px">
              <vue-slider
                v-model="done_task.qty"
                :silent="true"
                :min="0"
                :max="task.qty - task.done_qty"
              />
            </div>
          </div>

          <b-form-group label="Remark" label-for="remark">
            <Input
              v-model="done_task.remark"
              type="textarea"
              :rows="2"
              placeholder="Enter Your Remark"
            />
          </b-form-group>
          <div class="w-100 text-right">
            <button class="btn btn-primary" @click="UpdateWork()">
              Update
            </button>
          </div>
        </div>
        <app-timeline>
          <app-timeline-item
            :variant="activity.is_done == true ? 'success' : 'primary'"
            v-for="(activity, index) in activities"
            :key="index"
            v-if="activities.length"
          >
            <div
              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
            >
              <h6>
                {{ activity.start_date | time_format_short }} -
                {{ activity.end_date | time_format_short }}
                ({{ activity.end_date | date_short }})
              </h6>
              <small class="timeline-item-time text-nowrap text-muted ml-1">{{
                getHour(activity.done_duration)
              }}</small>
            </div>
            <b-badge variant="primary" class="mb-1"
              >{{ activity.done_percentage }}%
              <span v-if="activity.done_qty > 0"
                >- {{ activity.done_qty }} Quantity Done</span
              ></b-badge
            >
            <p>{{ activity.remark }}</p>
            <b-avatar-group size="35px">
              <b-avatar
                v-for="(employee, employee_index) in activity.work_employees"
                :key="employee_index"
                v-if="activity.work_employees.length && employee.employee"
                variant="secondary"
                v-b-tooltip.hover
                :title="employee.employee.name"
                :text="getChar(employee.employee.name)"
                class="pull-up"
              />
            </b-avatar-group>
          </app-timeline-item>
        </app-timeline>
      </div>
    </Drawer>
    <Modal
      :title="(form_task.id == '' ? 'Create' : 'Update') + ' Task'"
      v-model="create_task_model"
      :mask-closable="false"
      :footer-hide="true"
      :closable="false"
      width="700"
    >
      <validation-observer ref="taskValidation">
        <b-form
          class="auth-login-form"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row mb-2">
            <div class="col-md-6">
              <b-form-group label="Task Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="Task Title"
                  rules="required"
                >
                  <Input
                    name="title"
                    type="text"
                    v-model="form_task.title"
                    id="title"
                    placeholder="Enter Task Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Select Type" label-for="type">
                <validation-provider
                  #default="{ errors }"
                  name="Type"
                  rules="required"
                >
                  <Select
                    v-model="form_task.type"
                    filterable
                    name="type"
                    id="type"
                    placeholder="Select Type"
                  >
                    <Option :value="1">Check List</Option>
                    <Option :value="2">Time Tracker</Option>
                    <Option :value="3">Progressbar</Option>
                    <Option :value="4">Feedback</Option>
                    <Option :value="5">Hour</Option>
                    <Option :value="6">Quantity</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Task Quantity" label-for="qty">
                <validation-provider
                  #default="{ errors }"
                  name="Task Quantity"
                  :rules="form_task.type == 6 ? 'required' : ''"
                >
                  <InputNumber
                    name="qty"
                    v-model="form_task.qty"
                    id="qty"
                    placeholder="Enter Task Quantity"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Task Duration (Hour)" label-for="duration">
                <validation-provider
                  #default="{ errors }"
                  name="Task Duration"
                  :rules="form_task.type == 5 ? 'required' : ''"
                >
                  <InputNumber
                    name="duration"
                    v-model="form_task.duration"
                    id="duration"
                    placeholder="Enter Task Duration"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Start Date" label-for="start_date">
                <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form_task.start_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select Start Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="End Date" label-for="end_date">
                <validation-provider
                  #default="{ errors }"
                  name="End Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form_task.end_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select End Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Select Priority" label-for="priority">
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <Select
                    v-model="form_task.priority"
                    filterable
                    name="priority"
                    id="priority"
                    placeholder="Select Priority"
                  >
                    <Option :value="1">High</Option>
                    <Option :value="2">Medium</Option>
                    <Option :value="3">Low</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Is Progress" label-for="is_progress">
                <validation-provider
                  #default="{ errors }"
                  name="Is Progress"
                  :rules="form_task.id == '' ? 'required' : ''"
                >
                  <Select
                    v-model="form_task.is_progress"
                    filterable
                    name="is_progress"
                    id="is_progress"
                    placeholder="Is Progress"
                  >
                    <Option :value="1">Not Started</Option>
                    <Option :value="2">On Going</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group label="Select Employee" label-for="employee_id">
                <validation-provider
                  #default="{ errors }"
                  name="Employee"
                  rules="required"
                >
                  <Select
                    multiple
                    v-model="form_task.employee_id"
                    filterable
                    name="employee_id"
                    id="employee_id"
                    placeholder="Select Employee"
                  >
                    <Option
                      :value="employee.id"
                      v-for="(employee, index) in employees"
                      :key="index"
                      v-if="employees.length"
                      >{{ employee.name }}</Option
                    >
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                label="Description"
                label-for="description"
                rules="required"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <Input
                    v-model="form_task.description"
                    type="textarea"
                    :rows="2"
                    placeholder="Enter Your Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="text-right">
            <b-button
              type="submit"
              class="mx-1"
              variant="outline-primary"
              @click="CloseTask"
            >
              Close
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              @click="validationTaskForm"
            >
              {{ form_task.id == "" ? "Create" : "Update" }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </Modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BRow,
  BCol,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BForm,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import VueSlider from "vue-slider-component";
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    BAvatarGroup,
    BAvatar,
    VuePerfectScrollbar,
    VBTooltip,
    flatPickr,
    BButton,
    BListGroup,
    BListGroupItem,
    VueSlider,
    BImg,
    BCardBody,
    BCardHeader,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BMediaBody,
    BMediaAside,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      form_task: {
        id: "",
        main_task_id: null,
        default_task_id: null,
        project_id: null,
        title: null,
        start_date: null,
        end_date: null,
        priority: null,
        is_progress: null,
        qty: null,
        duration: null,
        type: null,
        employee_id: [],
        description: null,
      },
      create_task_model: false,
      search: {
        search_data: "",
        is_progress: null,
        priority: null,
        work_type: null,
        paginate: 15,
        page: 1,
      },
      done_task: {
        task_id: null,
        start_date: null,
        end_date: null,
        qty: 0,
        employee_id: [],
        percentage: 0,
        remark: null,
      },
      tasks: {},
      task: {},
      activities: {},
      task_model: false,
      mqShallShowLeftSidebar: false,
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getEmployee");
  },
  methods: {
    getAddTaskModel() {
      this.create_task_model = true;
    },
    addTask() {
      axios
        .post("/app/project/tasks/store", this.form_task)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
          this.CloseTask();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.default_task_id)
              this.e(e.response.data.errors.default_task_id[0]);
            if (e.response.data.errors.main_task_id)
              this.e(e.response.data.errors.main_task_id[0]);
            if (e.response.data.errors.project_id)
              this.e(e.response.data.errors.project_id[0]);
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
            if (e.response.data.errors.priority)
              this.e(e.response.data.errors.priority[0]);
            if (e.response.data.errors.is_progress)
              this.e(e.response.data.errors.is_progress[0]);
            if (e.response.data.errors.qty)
              this.e(e.response.data.errors.qty[0]);
            if (e.response.data.errors.duration)
              this.e(e.response.data.errors.duration[0]);
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.employee_id)
              this.e(e.response.data.errors.employee_id[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
          }
        });
    },

    CloseTask() {
      this.form_task.id = "";
      this.form_task.main_task_id = null;
      this.form_task.title = null;
      this.form_task.start_date = null;
      this.form_task.end_date = null;
      this.form_task.priority = null;
      this.form_task.is_progress = null;
      this.form_task.employee_id = [];
      this.form_task.description = null;
      this.form_task.qty = null;
      this.form_task.duration = null;
      this.form_task.type = null;
      this.create_task_model = false;
    },

    validationTaskForm() {
      this.$refs.taskValidation.validate().then((success) => {
        if (success) {
          this.addTask();
        }
      });
    },
    getHour(hour) {
      if (hour > 0) {
        let day = 0;
        let ret_hour = 0;
        let ret_minute = 0;
        if (hour >= 8) {
          let hour_data = hour % 8;
          day = (hour - hour_data) / 8;

          if (hour_data >= 1) {
            let minutes = hour_data % 1;
            ret_hour = hour_data - minutes;
            ret_minute = Math.round(minutes * 60);
          } else {
            ret_minute = Math.round(hour_data * 60);
          }
        } else {
          if (hour >= 1) {
            let minutes = hour % 1;
            ret_hour = hour - minutes;
            ret_minute = Math.round(minutes * 60);
          } else {
            ret_minute = Math.round(hour * 60);
          }
        }
        return (
          (day > 0 ? day + " Day " : "") +
          ((day > 0 && ret_minute > 0) || ret_hour > 0
            ? ret_hour + " Hour "
            : "") +
          (ret_minute > 0 ? ret_minute + " Minutes" : "")
        );
      } else {
        return 0;
      }
    },
    UpdateWork() {
      if (
        this.done_task.start_date == null ||
        this.done_task.start_date == ""
      ) {
        return this.i("Start Time Field Is Required :(");
      }
      if (this.done_task.end_date == null || this.done_task.end_date == "") {
        return this.i("End Time Field Is Required :(");
      }

      if (this.done_task.remark == null || this.done_task.remark == "") {
        return this.i("Remark Field Is Required :(");
      }

      if (this.task.type == 6 && this.done_task.qty < 1) {
        return this.i("Quantity Field Is Required :(");
      }

      if (
        this.task.type != 6 &&
        this.done_task.percentage - this.task.done_percentage < 1
      ) {
        return this.i("Percentage Field Is Required :(");
      }
      this.done_task.task_id = this.task.id;
      axios
        .post("/app/project/tasks/done", this.done_task)
        .then((res) => {
          this.s(res.data.message);
          this.done_task.percentage = res.data.task.done_percentage;
          this.getTaskModel(res.data.task.id);
          this.getResults();
          this.CloseWork();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.task_id)
              this.e(e.response.data.errors.task_id[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
            if (e.response.data.errors.qty)
              this.e(e.response.data.errors.qty[0]);
            if (e.response.data.errors.employee_id)
              this.e(e.response.data.errors.employee_id[0]);
            if (e.response.data.errors.percentage)
              this.e(e.response.data.errors.percentage[0]);
            if (e.response.data.errors.remark)
              this.e(e.response.data.errors.remark[0]);
          }
        });
    },
    CloseWork() {
      this.done_task.task_id = null;
      this.done_task.start_date = null;
      this.done_task.end_date = null;
      this.done_task.qty = null;
      this.done_task.employee_id = [];
      this.done_task.remark = null;
    },
    async getTaskModel(id) {
      const task = await this.callApi(
        "post",
        "/app/project/tasks/show/" + id,
        this.search
      );
      if (task.status == 200) {
        this.task = task.data.task;
        this.activities = task.data.activities;
        this.done_task.percentage = task.data.task.done_percentage;
        this.task_model = true;
      }
    },
    getChar(text) {
      return text
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
        .substring(0, 3);
    },

    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const tasks = await this.callApi(
        "post",
        "/app/project/tasks?page=" + this.search.page,
        this.search
      );
      if (tasks.status == 200) {
        this.tasks = tasks.data.tasks;
      }
    },

    getIsProgress(status) {
      this.search.is_progress = status;
      this.search.page = 1;
      this.getResults();
    },
    getPriority(priority) {
      this.search.priority = priority;
      this.search.page = 1;
      this.getResults();
    },
    getWorkType(type) {
      this.search.work_type = type;
      this.search.page = 1;
      this.getResults();
    },
    getDepartmentChar(text) {
      return text
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
        .substring(0, 3);
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
  },
  computed: {
    employees() {
      return this.$store.getters.getEmployee;
    },
    permission() {
      return this.$store.getters.getPermissions.task;
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }

  .cursor_pointer {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "~@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
